import React from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import cx from 'classnames'
import { useIntl } from 'intl'
import { useDevice } from 'device'
import { constants, getTradingItem, getAddItemProduct, getIs20mlMiniBottle } from 'helpers'
import { getTradingItemDiscount } from 'helpers/getters'
import { useAb, useFt } from 'hooks'
import { useCnstrcProductTrackingDataAttrs } from 'modules/constructorIO'

import { Text, Rating } from 'components/dataDisplay'
import { Button, buttonMessages } from 'components/inputs'
import { Href } from 'components/navigation'

import ProductPrice from 'compositions/ProductPrice/ProductPrice'
import ProductPriceRange from 'compositions/ProductPriceRange/ProductPriceRange'
import ProductImage from 'compositions/ProductImage/ProductImage'
import AddToButton, { type AddToButtonProps } from 'compositions/buttons/AddToButton/AddToButton'

import Labels from '../components/Labels/Labels'
import RetailPrice from '../components/RetailPrice/RetailPrice'
import Brand from '../components/Brand/Brand'


const ProductCardSmall: React.FunctionComponent<ProductCard.SmallCardProps> = (props) => {
  const { className, data, buttonProps, position, withoutLink, replaceRatingWithRetailPrice,
    isEcommerce, withEcommercePrefix = true, excludeLabels, excludeVolumes, bgColorClassName = 'bg-light-beige',
    lazy, onProductClick, onProductLinkClick, onProductButtonClick, dataAttributes, withProductPrice = true, withProductPriceRange = false,
    withTradingItemImage, withForwardedTradingItemUid = false } = props
  const { url, image, rebrandImage, brandInfo: { name: brand }, name, fullName, upchargePrice, rebrandLabels, rating, prices, typeGroup, tradingItems } = data

  const { tradingItem, isEcommerceProduct } = getTradingItem({
    product: data,
    isEcommerce,
    isFullSizeBottlePrioritized: isEcommerce,
    excludeVolumes,
  })

  const intl = useIntl()

  const isExtraProduct = typeGroup === 'PerfumeCase' || typeGroup === 'FragranceStorageBox'

  const isProductSaleBadgeEnabled = useFeatureIsOn(constants.features.productSaleBadge)
  const isHideCasesReviewsEnabled = useFt(constants.features.hideCasesReviews)
  const isRatingVisible = !isExtraProduct || !isHideCasesReviewsEnabled

  const isCustomMiniBottleImageEnabled = useAb(constants.abTests.miniBottlesMainImageChange) === 'b'
  const isCustomImageVisible = isEcommerceProduct && getIs20mlMiniBottle(tradingItem) && isCustomMiniBottleImageEnabled


  const productUrl = (() => {
    let finalUrl = url

    if (withForwardedTradingItemUid) {
      const hasQuery = /\?[^?]+$/.test(finalUrl)
      finalUrl = `${finalUrl}${hasQuery ? '&' : '?'}forwardedTradingItemUid=${tradingItem?.uid}`
    }

    if (withEcommercePrefix && isEcommerceProduct && typeGroup !== 'GiftSet') {
      finalUrl = `/ecommerce${finalUrl}`
    }

    return finalUrl
  })()

  const { volume, unit } = tradingItem?.volume || {}
  const productName = unit && unit !== 'pcs' && isEcommerceProduct && !withProductPriceRange ? `${name} · ${volume} ${unit}` : name

  let addToButtonProps: Partial<AddToButtonProps> = {
    type: isEcommerceProduct ? 'cart' : 'queue',
    product: getAddItemProduct({
      product: data,
      tradingItem,
      intl,
    }),
  }

  const { isMobile } = useDevice()

  const cnstrcTrackingDataAttrs = useCnstrcProductTrackingDataAttrs({
    id: data.uid,
    name: data.name,
    prices: tradingItem?.prices,
    variationId: tradingItem?.sku,
  })

  const linkClickHandler = () => {
    if (typeof onProductClick === 'function') {
      onProductClick({
        position,
        action: 'link',
      })
    }

    if (typeof onProductLinkClick === 'function') {
      onProductLinkClick(
        data,
        volume ? `${volume} ${unit}` : '',
        tradingItem?.prices?.discountPrice
      )
    }
  }

  const isWhite = bgColorClassName === 'bg-white'

  const topContent = (
    <>
      <ProductImage
        src={withTradingItemImage && tradingItem.image || rebrandImage || image}
        alt={fullName}
        bgColor={isWhite ? 'white' : 'light-beige'}
        remWidth={isMobile ? 180 : 152}
        lazy={lazy}
        skuSrc={isCustomImageVisible && tradingItem?.image}
      />
      <Brand className="mt-8 line-clamp-2 break-words" brand={brand} style="h8" color="gold-50" />
      <Text className="mt-4 line-clamp-2 break-words " message={productName} style="p4" data-testid="productName" />
      {
        replaceRatingWithRetailPrice ? (
          <RetailPrice className="mt-12" price={prices?.retail} />
        ) : (
          isRatingVisible && (
            <Rating className="mx-auto mt-12" value={rating?.avgRate} size={14} />
          )
        )
      }
    </>
  )

  const rootClassName = cx(className, 'relative flex flex-col justify-between p-12 text-center', !buttonProps && 'pb-32', bgColorClassName)
  const discount = isProductSaleBadgeEnabled && isEcommerceProduct && tradingItem && withProductPrice
    ? getTradingItemDiscount(tradingItem)
    : 0

  return (
    <div
      className={rootClassName}
      data-testid="productCardSmall"
      {...cnstrcTrackingDataAttrs}
      {...dataAttributes}
    >
      <Labels
        labels={rebrandLabels}
        excludeLabels={excludeLabels}
        upchargePrice={upchargePrice}
        isEcommerce={isEcommerceProduct}
        discount={discount}
        isSmallCard
        tradingItemType={tradingItem?.type}
      />
      {
        !withoutLink && url ? (
          <Href className="flex-auto" to={productUrl} onClick={linkClickHandler}>
            {topContent}
          </Href>
        ) : (
          <div className="flex-auto">
            {topContent}
          </div>
        )
      }
      {
        isEcommerceProduct && withProductPrice && !withProductPriceRange && (
          <ProductPrice className="mb-8 mt-16" data={tradingItem?.prices} uid={data.uid} />
        )
      }
      {
        isEcommerceProduct && withProductPriceRange && (
          <ProductPriceRange className="mb-8 mt-16" tradingItems={tradingItems?.items} />
        )
      }
      {
        Boolean(buttonProps) && (
          isEcommerceProduct && withProductPriceRange ? (
            <Button
              className="mt-16"
              title={buttonMessages.addToCart}
              to={productUrl}
              fullWidth
              size={38}
              onClick={linkClickHandler}
            />
          ) : (
            <AddToButton
              className="mt-16"
              size={38}
              fullWidth
              {...addToButtonProps}
              {...buttonProps as any}
              onClick={() => {
                if (typeof onProductClick === 'function') {
                  onProductClick({ position })
                }

                if (typeof buttonProps.onClick === 'function') {
                  buttonProps.onClick()
                }

                if (typeof onProductButtonClick === 'function') {
                  onProductButtonClick()
                }
              }}
            />
          )
        )
      }
    </div>
  )
}


export default React.memo(ProductCardSmall)
