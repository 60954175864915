import { useEffect, useMemo, useRef } from 'react'
import { useField, useFieldState } from 'formular'
import { useIntl } from 'intl'
import { formatProductLabelsForAnalytics, formatSize, getAddItemProduct } from 'helpers'
import { track } from 'analytics'
import { useCnstrcProductTrackingDataAttrs } from 'modules/constructorIO'


type UseProductCardDriftProps = {
  data: ProductFragment.Drift
  position: number
  isStarterSet: boolean
}

const useProductCardDrift = ({ data, position, isStarterSet }: UseProductCardDriftProps) => {
  const intl = useIntl()

  const { brandInfo, tradingItems, url } = data

  const { driftOption, varietyItems } = useMemo(() => {
    const option = data.drift?.find(({ starterSet }) => isStarterSet === starterSet) || data.drift?.[0]

    const driftOption = {
      ...option,
      price: option?.price && {
        standardPrice: option.price,
        discountPrice: option.discountPrice,
      },
    }

    const varietyItems = data.tradingItems?.items
      .filter(({ section }) => {
        return section !== 'Subscription'
      })
      .map((item) => ({
        value: item.uid,
        label: item.variety?.name,
      }))

    return {
      driftOption,
      varietyItems,
    }
  }, [ data, isStarterSet ])

  const isDriftStarterSet = driftOption?.starterSet ?? isStarterSet

  const isTrackParamsInitializedRef = useRef(false)

  const varietyField = useField({ value: varietyItems?.[0]?.value })
  const { value: selectedTradingItemUid } = useFieldState(varietyField)

  const selectedTradingItem = tradingItems?.items?.find(({ uid }) => uid === selectedTradingItemUid)
  const { volume, unit } = selectedTradingItem?.volume || {}

  const name = driftOption?.name || brandInfo.name
  const image = driftOption?.image || selectedTradingItem?.image || data?.rebrandImage || data?.image
  const price = driftOption?.price || selectedTradingItem?.prices
  const description = unit && unit !== 'pcs' ? `${data?.name} · ${volume} ${unit}` : data?.name
  const varietyName = selectedTradingItem?.variety?.name

  const addToButtonProps = {
    type: 'cart' as const,
    product: getAddItemProduct({
      product: data,
      tradingItem: selectedTradingItem,
      isDrift: true,
      isStarterSet: isDriftStarterSet,
      intl,
    }),
  }

  const productUrl = url && `/ecommerce${url}?driftVariant=${isDriftStarterSet ? 'starterSet' : 'refill'}&variety=${encodeURIComponent(varietyName)}`

  const trackParams = useMemo(() => {
    const { volume, unit } = selectedTradingItem?.volume || {}

    return {
      productId: data.id,
      productUid: data.uid,
      productVariantId: selectedTradingItem?.id,
      productVariantUid: selectedTradingItem?.uid,
      position,
      isStarterSet: isDriftStarterSet,
      productVariantName: selectedTradingItem?.variety?.name,
      productPrice: price?.discountPrice?.amount || price?.standardPrice?.amount,
      productLabels: formatProductLabelsForAnalytics({ product: data, intl }),
      productGender: data.gender,
      productFullName: data.fullName,
      productBrand: data.brandInfo.name,
      productCategory: data.category,
      productVolume: volume ? formatSize(volume, unit) : null,
    }
  }, [ intl, isDriftStarterSet, selectedTradingItem, data, price, position ])

  const cnstrcTrackingDataAttrs = useCnstrcProductTrackingDataAttrs({
    id: data.uid,
    name: data.name,
    prices: price,
    variationId: selectedTradingItem?.sku,
  })

  useEffect(() => {
    if (!isTrackParamsInitializedRef.current) {
      isTrackParamsInitializedRef.current = true
      return
    }
    track('Product variant select', trackParams)
  }, [ trackParams ])

  return {
    name,
    image,
    price,
    description,
    varietyField,
    varietyItems,
    addToButtonProps,
    url: productUrl,
    trackParams,
    cnstrcTrackingDataAttrs,
  }
}

export default useProductCardDrift
