import React from 'react'

import { Bone } from 'components/layout'


const ProductCardSmallSkeleton = () => (
  <div className="bg-light-beige flex flex-col items-center p-12" data-testid="productCardSmallSkeleton">
    <Bone aspect={1} pw={100} bgColor="light-beige" />
    <Bone className="mt-12 rounded" pw={50} h={12} bgColor="light-beige" />
    <Bone className="mt-8 rounded" pw={60} h={12} bgColor="light-beige" />
    <Bone className="mt-16 rounded" w={84} h={14} bgColor="light-beige" />
    <Bone className="mt-40" pw={100} h={38} bgColor="light-beige" />
  </div>
)


export default ProductCardSmallSkeleton
