export default {
  exclusive: {
    en: 'Exclusive',
  },
  plusFreeGift: {
    en: '+ Free Gift',
  },
  forHer: {
    en: 'for her',
  },
  forHim: {
    en: 'for him',
  },
  discount: {
    en: 'Sale {discount}% off',
  },
}
