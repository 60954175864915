import React from 'react'

import { Bone } from 'components/layout'


type ProductCardDetailedSkeletonProps = {
  bgColorClassName?: 'bg-light-beige' | 'bg-white'
}

const ProductCardDetailedSkeleton: React.FC<ProductCardDetailedSkeletonProps> = ({ bgColorClassName = 'bg-light-beige' }) => (
  <div className={`flex flex-col items-center px-24 pb-16 pt-12 ${bgColorClassName}`} data-testid="productCardDetailedSkeleton">
    <Bone pw={100} aspect={1} bgColor="light-beige" />
    <Bone className="mt-12 rounded" pw={50} h={20} bgColor="light-beige" />
    <Bone className="mt-12 rounded" pw={80} h={12} bgColor="light-beige" />
    <Bone className="mt-24 rounded" w={84} h={14} bgColor="light-beige" />
    <div className="mt-24 grid grid-flow-col gap-12">
      <Bone w={64} h={64} circle bgColor="light-beige" />
      <Bone w={64} h={64} circle bgColor="light-beige" />
      <Bone w={64} h={64} circle bgColor="light-beige" />
      <Bone w={64} h={64} circle bgColor="light-beige" />
    </div>
    <Bone className="mt-36 rounded" pw={100} h={12} bgColor="light-beige" />
    <Bone className="mt-8 rounded" pw={100} h={12} bgColor="light-beige" />
    <Bone className="mt-8 rounded" pw={80} h={12} bgColor="light-beige" />
    <Bone className="mt-24" pw={100} h={48} bgColor="light-beige" />
  </div>
)


export default ProductCardDetailedSkeleton
