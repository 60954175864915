import React from 'react'
import cx from 'classnames'

import { Text } from 'components/dataDisplay'
import ProductImage, { type ProductImageProps } from 'compositions/ProductImage/ProductImage'


type NotesProps = {
  className?: string
  withBlendedImages?: boolean // TODO is it temporarily? - added on 16.09.2021 by sonatskiy
  bgColor?: ProductImageProps['bgColor']
  justify?: 'justify-center' | 'justify-start'
  items: {
    image?: string
    rebrandImage: string
    name: string
  }[]
}

const Notes: React.FunctionComponent<NotesProps> = (props) => {
  const { className, items, withBlendedImages, justify = 'justify-center', bgColor = 'light-beige' } = props

  return (
    <div className={cx('grid auto-cols-[64rem] grid-flow-col gap-12 text-center', justify, className)}>
      {
        // we should show only 4 notes by design
        items.slice(0, 4).map(({ image, rebrandImage, name }) => (
          <div key={name}>
            <ProductImage
              className={cx('mx-auto w-full', withBlendedImages && !rebrandImage ? 'mix-blend-multiply' : null)}
              src={rebrandImage || image}
              bgColor={bgColor}
              remWidth={64}
            />
            <Text className="mt-4" message={name} style="p5" color="gray-80" />
          </div>
        ))
      }
    </div>
  )
}


export default React.memo(Notes)
