import type { Label, RebrandLabel } from 'typings/graphql'


const useExcludeLabels = (labels: RebrandLabel, ignoreLabels: Label[] = []): RebrandLabel => {
  if (!labels || !ignoreLabels.length) {
    return labels
  }

  return Object.keys(labels).reduce((result, type) => {
    // have to check because of __typename field
    if (Array.isArray(labels[type])) {
      result[type] = labels[type].filter((label) => !ignoreLabels.includes(label))
    }
    else {
      result[type] = labels[type]
    }

    return result
  }, {} as RebrandLabel)
}

export default useExcludeLabels
