export default {
  scentsTitle: {
    en: 'Choose your scent',
  },
  refillName: {
    en: 'Drift car freshener refill',
  },
  starterSetName: {
    en: 'Drift Starter Set',
  },
}
