import React from 'react'
import { useIntl } from 'intl'

import { Text } from 'components/dataDisplay'

import messages from './messages'


type RetailPriceProps = {
  className?: string
  price: Intl.PriceValue
}

const RetailPrice: React.FunctionComponent<RetailPriceProps> = ({ className, price }) => {
  const intl = useIntl()

  if (!price) {
    return null
  }

  const formattedPrice = intl.formatPrice(price, { removeZeroCents: true })

  return (
    <Text
      className={className}
      message={{ ...messages.text, values: { price: formattedPrice } }}
      style="p5"
      data-testid="retailPrice"
    />
  )
}


export default React.memo(RetailPrice)
